import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FaWhatsapp } from 'react-icons/fa';
import backgroundImage from '../assets/images/home.webp';
import '../styles/animations.css';

const Presentation = () => {

  const handleWhatsappClick = () => {
    window.open('https://wa.me/your-whatsapp-number', '_blank');
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 0; // Ajusta este valor según la altura de tu navbar
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <section id="inicio" className="relative bg-cover bg-center h-screen overflow-hidden" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Gradiente para mejorar la legibilidad del texto */}
      <div className="absolute inset-0 bg-black opacity-85"></div>
      
      {/* Contenedor principal con animación de entrada */}
      <div className="container mx-auto px-4 py-20 relative z-10 flex flex-col justify-center items-center text-center">
        <div className="max-w-2xl mx-auto">
          {/* Animación de entrada para el título */}
          <h1 className="text-4xl md:text-6xl font-bold text-white">
            Bienvenidos a ConstrudioTec
          </h1>
          <p className="mt-4 text-xl md:text-2xl text-white font-bold">
            Innovamos el futuro del desarrollo de software
          </p>
          <p className="mt-6 text-lg text-white font-semibold">
            En Construdiotec, ofrecemos soluciones personalizadas y de vanguardia para transformar tu visión en realidad. Nuestro equipo de expertos está dedicado a brindar resultados excepcionales.
          </p>
          <ul className="mt-8 space-y-4 text-left md:text-center text-white">
            <li className="flex md:justify-center items-center">
              <svg className="w-6 h-6 text-blue-500 mr-2 transform transition-transform duration-300 hover:scale-110" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
              Soluciones a medida para tu negocio
            </li>
            <li className="flex md:justify-center items-center">
              <svg className="w-6 h-6 text-blue-500 mr-2 transform transition-transform duration-300 hover:scale-110" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
              Tecnología de punta
            </li>
            <li className="flex md:justify-center items-center">
              <svg className="w-6 h-6 text-blue-500 mr-2 transform transition-transform duration-300 hover:scale-110" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
              Soporte y mantenimiento continuo
            </li>
          </ul>
          {/* Botones con efecto de pulsación */}
          <div className="mt-8 flex justify-center space-x-4">
          <a 
            href="https://wa.me/573234858848" // Reemplaza con el enlace de WhatsApp deseado
            className="inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button 
              className="bg-green-600 text-white px-6 py-3 rounded flex items-center justify-center space-x-2 transform transition-transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 font-semibold"
            >
              <FaWhatsapp className="w-6 h-6" />
              <span>WhatsApp</span>
            </button>
          </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
