import React from 'react';
import { FaCode, FaMobileAlt, FaCloud, FaDatabase } from 'react-icons/fa'; // Importa algunos íconos de react-icons
import ServiceCard from '../components/ServiceCard'; // Ajusta la ruta según la estructura de tu proyecto

const Services = () => {
  const services = [
    {
      icon: <FaCode className="w-12 h-12" />,
      title: "Desarrollo Web",
      description: "Construimos sitios web modernos y escalables con las últimas tecnologías."
    },
    {
      icon: <FaMobileAlt className="w-12 h-12" />,
      title: "Aplicaciones Móviles",
      description: "Desarrollamos aplicaciones móviles intuitivas y de alto rendimiento."
    },
    {
      icon: <FaCloud className="w-12 h-12" />,
      title: "Servicios en la Nube",
      description: "Ofrecemos soluciones en la nube que garantizan seguridad y escalabilidad."
    },
    {
      icon: <FaDatabase className="w-12 h-12" />,
      title: "Gestión de Datos",
      description: "Proveemos gestión eficiente y segura de bases de datos."
    },
  ];

  return (
    
    <section id="servicios" className="py-20 bg-gray-100">
      
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12 text-gray-800">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} icon={service.icon} title={service.title} description={service.description} />
            
          ))}
        </div>
      </div>
    </section>
    
  );
  
};

export default Services;
