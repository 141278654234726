import React, { useState } from 'react';

const Faqs = () => {
  // Estado para rastrear cuál FAQ está abierto
  const [openFaq, setOpenFaq] = useState(null);

  // Función para manejar el clic en un FAQ
  const handleToggle = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // Lista de preguntas y respuestas
  const faqData = [
    {
      question: "¿Qué servicios ofrece Construdiotec?",
      answer: "Construdiotec ofrece una amplia gama de servicios, incluyendo desarrollo web, aplicaciones móviles, servicios en la nube y gestión de datos.",
    },
    {
      question: "¿Cómo puedo contactar con su equipo?",
      answer: "Puedes contactarnos a través de nuestro WhatsApp 3234858848 o enviando un correo electrónico a construdiotec@gmail.com.",
    },
    {
      question: "¿Qué tipo de proyectos aceptan?",
      answer: "Aceptamos una variedad de proyectos, desde pequeños sitios web hasta aplicaciones empresariales complejas. Nos adaptamos a las necesidades de cada cliente.",
    },
    {
      question: "¿Cuál es el proceso para comenzar un proyecto?",
      answer: "Nuestro proceso comienza con una consulta inicial para entender tus necesidades. Luego, creamos una propuesta detallada y, una vez aprobada, iniciamos el desarrollo del proyecto.",
    },
    {
      question: "¿Ofrecen soporte después de completar un proyecto?",
      answer: "Sí, ofrecemos soporte continuo y mantenimiento para asegurar que tu proyecto funcione perfectamente después del lanzamiento.",
    },
  ];

  return (
    <section id='faqs' className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12">Preguntas Frecuentes</h2>
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div key={index} className="border-b border-gray-300">
              <button
                onClick={() => handleToggle(index)}
                className="w-full text-left py-4 focus:outline-none"
              >
                <h3 className="text-xl font-semibold text-gray-800 flex justify-between items-center">
                  {faq.question}
                  <span className={`ml-4 transform transition-transform duration-300 ${openFaq === index ? 'rotate-180' : ''}`}>
                    ▼
                  </span>
                </h3>
              </button>
              {openFaq === index && (
                <div className="pl-6 pr-4 pb-4 text-gray-700">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
