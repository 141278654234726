import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 0; // Ajusta este valor según la altura de tu navbar
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <footer className="bg-gradient-to-r from-gray-700 via-gray-900 to-black text-white py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo y Descripción */}
          <div className="md:w-1/3 text-center md:text-left mb-6 md:mb-0">
            <h3 className="text-2xl font-bold">Construdiotec</h3>
            <p className="mt-4 text-gray-400">
              Innovamos el futuro del desarrollo de software con soluciones personalizadas y de vanguardia.
            </p>
          </div>

          {/* Links de Navegación */}
          <div className="md:w-1/3 mb-6 md:mb-0 text-center">
            <h4 className="text-lg font-semibold mb-4">Enlaces Rápidos</h4>
            <ul className="space-y-2">
              <li><HashLink smooth to="/#inicio" scroll={scrollWithOffset} className="hover:text-blue-500 transition">Inicio</HashLink></li>
              <li><HashLink smooth to="/#servicios" scroll={scrollWithOffset} className="hover:text-blue-500 transition">Servicios</HashLink></li>
              <li><HashLink smooth to="/#acerca-de" scroll={scrollWithOffset} className="hover:text-blue-500 transition">Sobre Nosotros</HashLink></li>
              <li>
                <a 
                  href="https://wa.me/573234858848" // Reemplaza con el enlace de WhatsApp deseado
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-blue-500 transition"
                >
                  Contacto
                </a>
              </li>
            </ul>
          </div>

          {/* Redes Sociales */}
          <div className="md:w-1/3 flex justify-center md:justify-end">
            {/* <a href="#" className="text-gray-400 hover:text-white transition mx-2"><FaFacebookF /></a> */}
            <a href="https://www.instagram.com/construdio.tec?igsh=bXJ2dzlja29xaDV6" className="text-gray-400 hover:text-white transition mx-2"><FaInstagram /></a>
            <a href="#" className="text-gray-400 hover:text-white transition mx-2"><FaLinkedinIn /></a>
            <a href="https://wa.me/573234858848" target='_blank' className="text-gray-400 hover:text-white transition mx-2"><FaWhatsapp /></a>
          </div>
        </div>

        <div className="mt-8 text-center text-gray-500">
          &copy; {new Date().getFullYear()} Construdiotec. Todos los derechos reservados.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
