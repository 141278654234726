import React from 'react';
import Slider from 'react-slick';
import { FaCogs, FaUserShield, FaChartLine, FaUsers, FaLock, FaMobileAlt } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSoftware = () => {
  const benefits = [
    {
      icon: <FaCogs className="w-10 h-10 text-[#232753]" />,
      title: "Adaptabilidad Total",
      description: "Un software a medida se adapta completamente a las necesidades específicas de tu negocio, permitiéndote trabajar de manera más eficiente y efectiva."
    },
    {
      icon: <FaUserShield className="w-10 h-10 text-[#232753]" />,
      title: "Seguridad Mejorada",
      description: "Al ser desarrollado exclusivamente para tu empresa, un software a medida ofrece una seguridad superior, minimizando los riesgos de vulnerabilidades."
    },
    {
      icon: <FaChartLine className="w-10 h-10 text-[#232753]" />,
      title: "Escalabilidad",
      description: "El software a medida puede evolucionar junto con tu negocio, permitiendo añadir nuevas funcionalidades a medida que tu empresa crece."
    },
    {
      icon: <FaUsers className="w-10 h-10 text-[#232753]" />,
      title: "Soporte Personalizado",
      description: "Obtén soporte técnico especializado y personalizado, asegurando que cualquier problema se resuelva de manera rápida y eficiente."
    },
    {
      icon: <FaLock className="w-10 h-10 text-[#232753]" />,
      title: "Propiedad Total",
      description: "Con un software a medida, tu empresa posee los derechos completos sobre el software, evitando dependencias de terceros."
    },
    {
      icon: <FaMobileAlt className="w-10 h-10 text-[#232753]" />,
      title: "Integración Perfecta",
      description: "Un software a medida se puede integrar perfectamente con los sistemas y herramientas que tu empresa ya utiliza, optimizando los procesos internos."
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800">¿Por qué Elegir un Software a Medida?</h2>
        <Slider {...settings}>
          {benefits.map((benefit, index) => (
            <div key={index} className="p-4">
              <div className="bg-white p-8 rounded-lg shadow-md text-center">
                <div className="flex justify-center mb-4">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-gray-800">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default CustomSoftware;
