// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/logo.png';
import '../styles/animations.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 0; // Ajusta este valor según la altura de tu navbar
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollWithOffset2 = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 80; // Ajusta este valor según la altura de tu navbar
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <nav className="bg-white p-4 shadow-md">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="flex-shrink-0">
            <img src={logo} alt="Construdiotec Logo" className="h-auto w-56" />
          </div>
          <div className="hidden md:flex md:items-center space-x-6">
            <HashLink
              smooth
              to="/#"
              className="relative text-gray-800 px-8 py-2 rounded-md text-sm font-medium"
            >
              Inicio
              <span className="absolute bottom-0 left-0 w-0 h-1 bg-gray-800 transition-all duration-300"></span>
            </HashLink>
            <HashLink
              smooth
              to="/#servicios"
              scroll={scrollWithOffset}
              className="relative text-gray-800 px-8 py-2 rounded-md text-sm font-medium"
            >
              Servicios
              <span className="absolute bottom-0 left-0 w-0 h-1 bg-gray-800 transition-all duration-300"></span>
            </HashLink>
            <HashLink
              smooth
              to="/#acerca-de"
              scroll={scrollWithOffset}
              className="relative text-gray-800 px-8 py-2 rounded-md text-sm font-medium"
            >
              Acerca de
              <span className="absolute bottom-0 left-0 w-0 h-1 bg-gray-800 transition-all duration-300"></span>
            </HashLink>
            <HashLink
              smooth
              to="/#faqs"
              scroll={scrollWithOffset}
              className="relative text-gray-800 px-8 py-2 rounded-md text-sm font-medium"
            >
              FAQS
              <span className="absolute bottom-0 left-0 w-0 h-1 bg-gray-800 transition-all duration-300"></span>
            </HashLink>
            <a href="https://wa.me/573234858848"
              target='_blank'
              className="text-white bg-[#232753] hover:bg-[#434a92] hover:text-white px-10 py-2 rounded-md text-sm font-medium"
            >
              Contáctanos
            </a>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-gray-800 hover:text-gray-600 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
        {/* Menú desplegable */}
        {isOpen && (
          <div className="mt-2 flex flex-col md:hidden">
            <HashLink
              smooth
              to="/#"
              onClick={() => setIsOpen(false)}
              className="text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Inicio
            </HashLink>
            <HashLink
              smooth
              to="/#servicios"
              scroll={scrollWithOffset2}
              onClick={() => setIsOpen(false)}
              className="text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Servicios
            </HashLink>
            <HashLink
              smooth
              to="/#acerca-de"
              scroll={scrollWithOffset2}
              onClick={() => setIsOpen(false)}
              className="text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Acerca de
            </HashLink>
            <HashLink
              smooth
              to="/#faqs"
              scroll={scrollWithOffset2}
              onClick={() => setIsOpen(false)}
              className="text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              FAQS
            </HashLink>
            <a href="https://wa.me/573234858848"
              target='_blank'
              onClick={() => setIsOpen(false)}
              className="text-white bg-[#232753] hover:bg-[#434a92] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Contactanos
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
