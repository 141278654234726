// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './home/home'; // Asegúrate de que la ruta y el nombre coincidan
import Services from './home/Services';
import WorkProcess from './home/WorkProcess';
import Contact from './home/Contact';
import Faqs from './home/Faqs';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/servicios" element={<Services />} />
        <Route path="/acerca-de" element={<WorkProcess />} />
        <Route path="/faqs" element={<Faqs />} />
      </Routes>
    </Router>
  );
};

export default App;
