import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import jsLogo from '../assets/images/jsLogo.png';
import reactLogo from '../assets/images/reactLogo.png';
import nodeLogo from '../assets/images/nodeLogo.png';
import postgresqlLogo from '../assets/images/postgresqlLogo.png';
import tailwindLogo from '../assets/images/tailwindLogo.png';
import awsLogo from '../assets/images/awsLogo.png';
import herokuLogo from '../assets/images/herokuLogo.png';
import nestLogo from '../assets/images/nestLogo.png';
import angularLogo from '../assets/images/angularLogo.png';
import laravelogo from '../assets/images/laravelogo.png';
import javalogo from '../assets/images/javalogo.png';

const TechnologiesSlider = () => {
  const technologies = [
    { name: 'JavaScript', logo: jsLogo },
    { name: 'React', logo: reactLogo },
    { name: 'Node.js', logo: nodeLogo },
    { name: 'PostgreSQL', logo: postgresqlLogo },
    { name: 'Tailwind CSS', logo: tailwindLogo },
    { name: 'AWS', logo: awsLogo },
    { name: 'Heroku', logo: herokuLogo },
    { name: 'NestJS', logo: nestLogo },
    { name: 'Angular', logo: angularLogo },
    { name: 'Laravel', logo: laravelogo },
    { name: 'Java', logo: javalogo }
  ];

  const Arrow = ({ className, style, onClick, icon }) => (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1, marginLeft: '20px', marginRight: '20px' }}
      onClick={onClick}
    >
      
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: <Arrow icon={<FaArrowRight style={{ color: 'white', fontSize: '20px' }} />} />,
    prevArrow: <Arrow icon={<FaArrowLeft style={{ color: 'white', fontSize: '20px' }} />} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    appendDots: dots => (
      <div style={{ padding: '5px', textAlign: 'center' }}>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: '#fff',
          display: 'inline-block',
          margin: '0 5px'
        }}
      ></div>
    )
  };

  return (
    <section className="py-16" style={{ backgroundColor: '#232753' }}>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-white">Tecnologías que Utilizamos</h2>
        <Slider {...settings}>
          {technologies.map((tech, index) => (
            <div key={index} className="flex justify-center items-center p-2">
              <img src={tech.logo} alt={`${tech.name} Logo`} className="w-20 h-20 md:w-24 md:h-24 object-contain mx-auto" />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TechnologiesSlider;
