import React from 'react';
import Slider from 'react-slick';
import { FaRegHandshake, FaProjectDiagram, FaCode, FaCheckCircle, FaCloudUploadAlt, FaTools } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const WorkProcess = () => {
  const steps = [
    {
      icon: <FaRegHandshake className="w-10 h-10 text-[#232753]" />,
      title: "Consultoría Inicial",
      description: "Entendemos tus necesidades y objetivos del proyecto para proporcionar una solución adecuada."
    },
    {
      icon: <FaProjectDiagram className="w-10 h-10 text-[#232753]" />,
      title: "Planificación y Diseño",
      description: "Creamos un plan detallado y diseñamos la solución personalizada que se ajusta a tus requisitos."
    },
    {
      icon: <FaCode className="w-10 h-10 text-[#232753]" />,
      title: "Desarrollo",
      description: "Codificamos la solución siguiendo el diseño y los requisitos establecidos para el proyecto."
    },
    {
      icon: <FaCheckCircle className="w-10 h-10 text-[#232753]" />,
      title: "Pruebas",
      description: "Realizamos pruebas exhaustivas para asegurar que el software funcione correctamente y cumpla con los estándares de calidad."
    },
    {
      icon: <FaCloudUploadAlt className="w-10 h-10 text-[#232753]" />,
      title: "Despliegue",
      description: "Implementamos la solución en el entorno de producción para que esté disponible para los usuarios finales."
    },
    {
      icon: <FaTools className="w-10 h-10 text-[#232753]" />,
      title: "Mantenimiento y Optimización",
      description: "Proporcionamos soporte continuo y optimizamos el software según el feedback de los usuarios y las necesidades cambiantes."
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section id='acerca-de' className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800">Nuestro Proceso de Trabajo</h2>
        <Slider {...settings}>
          {steps.map((step, index) => (
            <div key={index} className="p-4">
              <div className="bg-white p-8 rounded-lg shadow-md text-center">
                <div className="flex justify-center mb-4">
                  {step.icon}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-gray-800">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default WorkProcess;
