import Footer from "../components/Footer";
import Contact from "./Contact";
import CustomSoftware from "./CustomSoftware";
import Faqs from "./Faqs";
import Presentation from "./presentacion";
import Services from "./Services";
import TechnologiesSlider from "./TechnologiesSlider";
import WorkProcess from "./WorkProcess";


function Home() {
  return (
    <>
      <Presentation/>
      <Services/>
      <TechnologiesSlider/>
      <WorkProcess/>
      <CustomSoftware/>
      <Faqs/>
      <Footer/>
    </>
  );
}

export default Home;
